<template>
  <div class="drag_and_drop_view">
    <div
      ref="pasteToUploadListener"
      class="bg-gray-100 border border-gray-300 container"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
      tabindex="1"
      @mouseover="(e) => e.currentTarget.focus()"
      @click="() => $refs['dnd_file_upload-input'].click()"
    >
      <input
        type="file"
        multiple
        name="fields[assetsFieldHandle][]"
        id="assetsFieldHandle"
        class="hidden"
        @change="onChange"
        ref="dnd_file_upload-input"
        accept=".pdf,.jpg,.jpeg,.png"
      >
      <div class="instruction-text">
        Drag & drop files here or click here to browse
      </div>
      <div class="hint-text">Mouse over or click here to paste from clipboard</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DragAndDropView',
  components: {

  },
  props: {
    onDnDChange: {
      type: Function,
    },
  },
  data: () => {
    return {
    };
  },
  computed: {},
  mounted() {
    window.addEventListener('paste', this.pasteFunction);
  },
  created() { },
  unmounted() {
    window.removeEventListener('paste', this.pasteFunction);
  },
  methods: {
    /**
     * @param {ClipboardEvent} pasteEvent
     */
    pasteFunction(pasteEvent) {
      if (document.activeElement != this.$refs.pasteToUploadListener) {
        return;
      }

      var items = Array.from(pasteEvent.clipboardData.files);

      /**
       * @param {File} item
       * @return {boolean}
       */
      const imageFilter = (item) => {
        // TODO: filter using mime type
        // return item.type.includes('image');
        return true;
      };

      const images = items.filter(imageFilter);
      if (images.length) {
        this.onChange({ _files: images });
      } else {
        this.$toast.error("No image in clipboard", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      }
    },
    onChange({ _files }) {
      const filelist = _files || this.$refs['dnd_file_upload-input'].files;
      this.onDnDChange({ filelist: filelist });
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs['dnd_file_upload-input'].files = event.dataTransfer.files;
      this.onChange(event.dataTransfer.files); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
  },
};
</script>

<style lang="scss" scoped>
.drag_and_drop_view  {
  min-width: 100%;
  margin-bottom: 1rem;

  .instruction-text {
    font-size: 1.2rem;
    cursor: pointer;
    color: #20a8d8;
    transition: color 0.1s ease-in-out;
    text-align: center;
  }
}

.container {
  position: relative;
  border: 1px solid #EAEAEA;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  cursor: pointer;


  height: 6rem;
  width: 100%;
  max-width: 100%;
  // min-height: 5rem;
  // min-width: 50%;
  // max-width: 50rem;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease-in-out;

  &:focus {
    border-color: rgb(0, 0, 0) !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    .instruction-text {
      color: #1f98c4;
    }
  }

  .hint-text {
    position: absolute;
    text-align: center;
    bottom: 0.5em;
    left: 0;
    right: 0;
    font-size: 75%;
    color: gray
  }
}
</style>